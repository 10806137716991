.shareAnnonceModal {
    .sharingOptionsContainer {
        display: flex;
        justify-content: center;

        .fbk {
            a {
                background-color: rgb(49, 111, 203) !important;
                color: #fff!important;
            }
        }

        .whatsapp {
            margin-left: 10px;

            a {
                background-color: rgb(49, 203, 131) !important;
                color: #fff!important;
            }
        }

        .mail {
            margin-left: 10px;

            a {
                background-color: rgb(241, 159, 17) !important;
                color: #fff!important;
            }
        }



    }
}