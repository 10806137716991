.annonces {
   display: block;
    .disposition {
      display: flex;
      justify-content: flex-end;
      border: 1px solid #d9d9d9;
      padding: 1rem;
      margin-bottom: 1rem;
      span {
         margin: 0.2rem;

         &:hover {
            cursor: pointer;
         }

         i.active {
            color: #EEAB26;
         }
      }
   }

   .ann {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid #d9d9d9;
      padding: 0.6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .annImage {
         flex-grow: 1;
         flex-basis: 0;
         a img {
            max-width: 100%;
         }

      }

      .annText {
         flex-grow: 3;
         margin-left: 1rem;
      }
   }

   .cardTitle {
      span{
         margin-left: 1rem;
      }
    
   }

}
