@mixin maci-form-check {
    display: flex;
    min-height: 1.5rem;
    margin-bottom: 0.125rem;
    align-items: center;
    margin-right: 0.5em;

    label {
        margin-left: 5px;
        padding-top: 0.2em;

        :hover {
            color: #CCC;
        }
    }
  }
  